import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="夢に突き進むあなたへ。勇気をもらえる名言"
        pagepath="/feature/02/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/artist01.jpg"
							alt="夢に突き進むあなたへ。勇気をもらえる名言"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">夢に突き進むあなたへ。勇気をもらえる名言</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>夢に向かって突き進むあなた。</span>
                        <span>しかし、時には自信を失ってしまったり周りからの否定的な言葉で迷いが生まれることも。</span>
                        <span>
                          そんなあなたの背中を力強く押してくれる先人たちの名言をご紹介しましょう！<br />
                          アーティスト、芸術家、ビジネスマン、俳優。目標に向かって突き進む人は必見です。
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>劇作家で、演劇実験室「天井桟敷」の主宰であった寺山修司。</p>
                        <p>彼が書いた『書を捨てよ町へ出よう』は時代を超えて愛される名著です。</p>
                        <p>寺山は、<span className="f-l">多作で知られ、多数の文芸作品</span>を世に送り出しました。</p>
                        <p>そんな活動に生きたアーティストである寺山が語るこの言葉は、<span className="marker-b">アーティストの本質である「作り続けること」そして輝かしい未来のみにフォーカスする大切さと勇気</span>を与えてくれます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ロサンゼルス・エンゼルス所属の大谷翔平選手（2021年時点）が二刀流でMLBを席巻しています。</p>
                        <p>およそ百年前に同じように<span className="marker-b">二刀流で圧倒的な結果を残した伝説的プレイヤー</span>がいました。</p>
                        <p>それが<span className="f-l">ベーブ・ルース</span>です。</p>
                        <p>野球の神様と評されるベーブ・ルースは、</p>
                        <ul>
                          <li>・オールスターゲーム選出 20回</li>
                          <li>・最多得点8回</li>
                          <li>・最多四球11回</li>
                          <li>・リーグ最高出塁率10回</li>
                          <li>・リーグ最高長打率13回</li>
                          <li>・リーグ最高OPS13回</li>
                          <li>・通算サヨナラ本塁打12本</li>
                          <li>・通算満塁本塁打16本</li>
                          <li>・通算ランニング本塁打10本</li>
                        </ul>
                        <p>というとんでもない記録を打ち立てました。</p>
                        <p>この偉業の裏には、彼のこの名言から醸し出される、<span className="marker-b">あきらめない強さ</span>があったのかもしれませんね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>音楽史上極めて重要な作曲家の一人であり、日本では「楽聖」とも呼ばれるベートーヴェン。</p>
                        <p>輝かしい彼のキャリアの裏にはたくさんの苦労がありました。</p>
                        <p>その最大の困難は<span className="f-l">聴覚障害</span>でしょう。</p>
                        <p>音楽家でありながら耳が聞こえづらいと言う事は致命傷になりえます。</p>
                        <p>そんな状態でありながらも、彼が53歳のときに世紀の<span className="f-l">名作『交響曲第9番』</span>を完成させます。</p>
                        <p><span className="f-l">どんなに不幸で苦しい境遇でも、黙って耐え忍んだ</span>ベートーヴェンだからこそ、この名言に深みを生み出せるのですね。</p>

                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>チベット仏教ゲルク派のトップ、ダライ・ラマ14世の名言です。</p>
                        <p>中国共産軍からの侵略から逃れるために1959年にインドに亡命。</p>
                        <p>その後は、全世界にチベット仏教を広める活動に従事されています。</p>
                        <p>ダライ・ラマ14世は、<span className="marker-b">叡智ある言葉をユーモアに乗せて伝えることができる才能の持ち主。</span></p>
                        <p>当サイトでもたくさんのダライ・ラマ14世の名言を紹介しています。</p>
                        <p>この名言は、20世紀の発明王エジソンを連想させますね。</p>
                        <p>かたや宗教教団のトップ、かたや発明。フィールドは違えど<span className="f-l">似た価値観を持っていること</span>が興味深いですね。</p>
                        <p>わたしたちがチャレンジすることが、<span className="f-l">1回目のチャレンジで成功することなんてまずありませんよね。</span></p>
                        <p><span className="marker-y">失敗を繰り返す中で試行錯誤しフィードバックを得て新たにチャレンジする。</span></p>
                        <p>その継続の先にしか成功はない。</p>
                        <p>人生哲学を教えてくれる名言です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ユダヤ人の人生哲学を思い出させるような名言です。</p>
                        <p>ユダヤ人を迫害され続けてきた歴史があり、財産を物や金として所有することを嫌います</p>
                        <p>いつそれらが為政者によって没収されるか分からないからです。</p>
                        <p>そこで彼らは<span className="f-l">財産を教育</span>に注ぎ込みます。</p>
                        <p>得た知識や経験、そして叡智は、何人たりとも奪い取ることはできません。</p>
                        <p>このようにして知恵を得たユダヤ人は、全世界で成功しました。</p>
                        <p>トーマス・エジソンが偉大なのは、彼の発明が素晴らしかったことはもちろんですが、本当に価値があるのは</p>
                        <p><span className="marker-y">彼自身の発明にかける情熱、そして発明するための基礎的素養、そして不屈の継続力でしょう。</span></p>
                        <p>そのことを理解していたトーマスエジソンだからこそこの名言が生まれたのではないでしょうか。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>最初のトライで成功する人はごくわずかです。</p>
                        <p>そんな当たり前のことを、私たちはいつの間にか忘れてしまいます。</p>
                        <p>たった数回のチャレンジで、<span className="marker-y">「私には才能がない。」「私には向いていない」</span></p>
                        <p>と、早合点してしまいがちです。</p>
                        <p>偉人のたどってきた形跡を見ればわかります。</p>
                        <p>彼らが成功した要因は、<span className="f-l">決して才能だけではありません。</span></p>
                        <p><span className="f-l">不屈の闘志で何度もチャレンジし続けたからです。</span></p>
                        <p>そんな自然の大原則を端的に表してくれる名言です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>私たち人間は、<span className="f-l">過去に生きる生き物</span>と言っていいかもしれません。</p>
                        <p><span className="f-l">「あのとき、ああすればよかった」</span></p>
                        <p><span className="f-l">「こんなこと言うべきではなかった」</span></p>
                        <p><span className="f-l">「あの時のことを思うと穴に入りたい」</span></p>
                        <p>私たちが変えることができるのは現在だけです。</p>
                        <p>未来はまだ来ておらず、どうすることもできません。</p>
                        <p>最後は過ぎ去り、どうすることもできないからです。</p>
                        <p>そのことを知っている人は、現在に全力を尽くします。</p>
                        <p>仏教の考え方で<span className="f-l">今ここに集中する</span>と言う考え方があります</p>
                        <p>ヘレンケラーのこの名言も同じでしょう。</p>
                        <p>過去のことに思い悩まず、輝かしい未来を想像し、今に全力を尽くしましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>失敗に屈せずに夢を貫き続けたウォルトディズニーだからこそこの言葉が言えたのかもしれません。</p>
                        <p>勤めていた新聞社を<span className="marker-b">「創造性に欠けるから」</span>という理由で解雇されるという挫折。</p>
                        <p><span className="marker-b">立ち上げた会社を3回も倒産させた苦い経験。</span></p>
                        <p>しかし、そのような苦境にありながらも、一度抱いた夢をあきらめずに追い続けたのです。</p>
                        <p>もしかしたら今、あなたがいる場所は、<span className="f-l">ディズニーの1回目の失敗の途中かもしれません。</span></p>
                        <p>そこで諦めたら老若男女を感動させるディズニーランドもありませんでした。</p>
                        <p><span className="f-l">あなたのディズニーランドをあきらめないでください。</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ノーベル化学賞を受賞した田中耕一。</p>
                        <p>試薬に誤ってグリセリンを混ぜてしまったことが全ての始まりでした。</p>
                        <p>当時、日本国民を感動させた田中耕一のノーベル賞受賞には<span className="marker-b">そんな逸話がありました。</span></p>
                        <p>失敗から世紀の発見をした彼だからこそ、<span className="f-l">失敗についての捉え方は普通の人とは違います。</span></p>
                        <p>何かに失敗したり、つまずいたときにはこの田中の言葉を思い出してください。</p>
                        <p><span className="f-l">きっと失敗が楽しくなるはずです。</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ニーチェの思想と言えば超人思想が有名です。</p>
                        <p>超人とは<span className="marker-b">ルサンチマンを超越した人物</span>のこと。</p>
                        <p>ルサンチマンとは、<span className="marker-b">強者に対し仕返しを欲して鬱結（うっけつ）した、弱者の心のことを指します。</span></p>
                        <p>つまり、この言葉を現代風に解釈するならば<span className="f-l">どんな仕打ちを受けても、どんな境遇にあっても弱音を吐かず、おおらかに、恨みを持たず、ただ前向きに進んでいく</span>ことなのだと思います。</p>
                        <p>きっとそれがニーチェの言う<span className="f-l">英雄</span>なのでしょう。</p>
                        <p>私たちの心の中にも英雄はいます。</p>
                        <p>ただ私たちはその英雄の存在を忘れてしまっているのです。</p>
                        <p>作品を世の中に通ことを目指すあなただからこそ、隠れてしまったあなただけの英雄を呼び起こしてください。</p>
                      </div>

                    </div>
                  </div>

                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "bbdc575f-2baf-55ae-a80d-ba4230a0f9f9"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "cd4f57a0-09f2-5d97-9542-e08c42ecdfda"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "a10bb370-b7cc-540d-be9d-7308c4bf1d9f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "1645238a-2846-5ad8-bc5f-8cfea1ad2791"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "1766a2ca-92ab-5ce5-bc4d-0a506081c19e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "200c408b-36b2-5568-9438-2e2b4ce5bde1"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "47f4c717-5be1-575d-80d1-c97e3e6be01e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "778ef5ca-af5c-576a-b2e8-b3c6d0959bc8"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "d2ec599f-5201-54b9-a192-d9f193696da3"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "0897feca-8d79-5143-a1bd-78a286df19a0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home